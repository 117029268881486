import { template as template_3121e3672b3041ac848c604557ae5376 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3121e3672b3041ac848c604557ae5376(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
