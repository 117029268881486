import { template as template_92e34f9cf9a34d6daeb2f7d52cc5235f } from "@ember/template-compiler";
const FKLabel = template_92e34f9cf9a34d6daeb2f7d52cc5235f(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
