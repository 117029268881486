import { template as template_583020bda8a1417ea4fe411a5c15728d } from "@ember/template-compiler";
const WelcomeHeader = template_583020bda8a1417ea4fe411a5c15728d(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
